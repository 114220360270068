import { IPurchasable } from "../../../entities/Purchasable";

export const purchasableRandomProductC = {
  createdDate: "2023-08-28T15:03:12.720Z",
  updatedDate: "2023-08-28T15:03:12.720Z",
  deletedAt: null,
  id: "5a6e29ae-6a01-4b25-af62-b657f3791cda",
  shopifyId: "gid://shopify/Product/8644944429358",
  __uniqueShopifyId__: "566796a6-41ed-4589-86f4-cc8da3b9331b_gid://shopify/Product/8644944429358",
  receivedFromShopifyDate: "2023-08-30T13:08:14.135Z",
  shopifyUpdateDate: "2023-08-30T13:08:10.000Z",
  shopifyProductStorefrontId: "gid://shopify/Product/8644944429358",
  isActiveInShopify: true,
  isDraftOrArchived: false,
  purchasableName: "Random Product C",
  purchasableImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/cat1_382ea5b2-a841-47e9-8ee1-49e94be253bf.jpg?v=1693234989",
  ],
  purchasableSmallImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/cat1_382ea5b2-a841-47e9-8ee1-49e94be253bf.jpg?v=1693234989",
  ],
  description: "Random Product C description",
  smartrrTags: ["SmartrrFilter:Random Product"],
  vnts: [
    {
      createdDate: "2023-08-28T15:03:12.720Z",
      updatedDate: "2023-08-28T15:03:12.720Z",
      deletedAt: null,
      id: "ac1dd8f4-8030-4ce5-a445-a45df4d5cfc2",
      shopifyId: "gid://shopify/ProductVariant/46814024663342",
      receivedFromShopifyDate: "2023-08-30T13:08:14.135Z",
      shopifyUpdateDate: "2023-08-28T15:03:06.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46814024663342",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Small",
      purchasableVariantImages: [
        "https://cdn.shopify.com/s/files/1/0642/0375/1683/products/pexels-david-bares-216490_320x320@2x.jpg?v=1674763623",
      ],
      purchasableVariantSmallImages: [
        "https://cdn.shopify.com/s/files/1/0642/0375/1683/products/pexels-david-bares-216490_320x320@2x.jpg?v=1674763623",
      ],
      presentmentPrices: [
        { price: "3800", priceCurrency: "AED" },
        { price: "1600", priceCurrency: "AUD" },
        { price: "1400", priceCurrency: "CAD" },
        { price: "900", priceCurrency: "CHF" },
        { price: "22700", priceCurrency: "CZK" },
        { price: "7100", priceCurrency: "DKK" },
        { price: "995", priceCurrency: "EUR" },
        { price: "900", priceCurrency: "GBP" },
        { price: "8000", priceCurrency: "HKD" },
        { price: "3900", priceCurrency: "ILS" },
        { price: "1500", priceCurrency: "JPY" },
        { price: "14000", priceCurrency: "KRW" },
        { price: "16200", priceCurrency: "MXN" },
        { price: "4800", priceCurrency: "MYR" },
        { price: "1800", priceCurrency: "NZD" },
        { price: "4300", priceCurrency: "PLN" },
        { price: "11200", priceCurrency: "SEK" },
        { price: "1400", priceCurrency: "SGD" },
        { price: "1000", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48861221781806",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-C",

      inventoryQuantity: 9992,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
  ],
} as Partial<IPurchasable> as IPurchasable;

export const purchasableRandomProductB = {
  createdDate: "2023-08-18T08:48:33.456Z",
  updatedDate: "2023-08-18T08:48:33.456Z",
  deletedAt: null,
  id: "a87569c6-ecca-4094-a971-4dc64393420a",
  shopifyId: "gid://shopify/Product/8591125250350",
  __uniqueShopifyId__: "566796a6-41ed-4589-86f4-cc8da3b9331b_gid://shopify/Product/8591125250350",
  receivedFromShopifyDate: "2023-08-22T11:25:12.000Z",
  shopifyUpdateDate: "2023-08-22T11:25:12.000Z",
  shopifyProductStorefrontId: "gid://shopify/Product/8591125250350",
  isActiveInShopify: true,
  isDraftOrArchived: false,
  purchasableName: "Random Product B",
  purchasableImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/cat1_6c20568a-3b9d-4768-b953-28a2d4698d4b_320x320@2x.jpg?v=1692348509",
  ],
  purchasableSmallImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/cat1_6c20568a-3b9d-4768-b953-28a2d4698d4b_320x320@2x.jpg?v=1692348509",
  ],
  description: "",
  smartrrTags: ["SmartrrFilter:Random Product"],
  vnts: [
    {
      createdDate: "2023-08-18T08:48:33.456Z",
      updatedDate: "2023-08-18T08:48:33.456Z",
      deletedAt: null,
      id: "3c65e7f8-567a-48bf-b32a-d21b208e97e4",
      shopifyId: "gid://shopify/ProductVariant/46503037108526",
      __uniqueShopifyId__: "566796a6-41ed-4589-86f4-cc8da3b9331b_gid://shopify/ProductVariant/46503037108526",
      receivedFromShopifyDate: "2023-08-22T11:25:12.000Z",
      shopifyUpdateDate: "2023-08-22T11:20:50.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46503037108526",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Small",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "3800", priceCurrency: "AED", priceCountry: "AE" },
        { price: "1600", priceCurrency: "AUD", priceCountry: "AU" },
        { price: "1400", priceCurrency: "CAD", priceCountry: "CA" },
        { price: "900", priceCurrency: "CHF", priceCountry: "CH" },
        { price: "22400", priceCurrency: "CZK", priceCountry: "CZ" },
        { price: "7000", priceCurrency: "DKK", priceCountry: "DK" },
        { price: "995", priceCurrency: "EUR", priceCountry: "EU" },
        { price: "800", priceCurrency: "GBP", priceCountry: "GB" },
        { price: "8000", priceCurrency: "HKD", priceCountry: "HK" },
        { price: "3900", priceCurrency: "ILS", priceCountry: "IL" },
        { price: "1500", priceCurrency: "JPY", priceCountry: "JP" },
        { price: "14000", priceCurrency: "KRW", priceCountry: "KR" },
        { price: "16400", priceCurrency: "MXN", priceCountry: "MX" },
        { price: "4800", priceCurrency: "MYR", priceCountry: "MY" },
        { price: "1800", priceCurrency: "NZD", priceCountry: "NZ" },
        { price: "4200", priceCurrency: "PLN", priceCountry: "PL" },
        { price: "11100", priceCurrency: "SEK", priceCountry: "SE" },
        { price: "1400", priceCurrency: "SGD", priceCountry: "SG" },
        { price: "1000", priceCurrency: "USD", priceCountry: "US" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48550077661486",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-B-1",
      inventoryQuantity: 9992,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-08-18T08:48:33.456Z",
      updatedDate: "2023-08-18T08:48:33.456Z",
      deletedAt: null,
      id: "2fb9bd5e-6736-426a-988a-507bf21de2fc",
      shopifyId: "gid://shopify/ProductVariant/46503037141294",
      receivedFromShopifyDate: "2023-08-22T11:25:12.000Z",
      shopifyUpdateDate: "2023-08-18T08:48:28.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46503037141294",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Medium",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "7500", priceCurrency: "AED" },
        { price: "3200", priceCurrency: "AUD" },
        { price: "2800", priceCurrency: "CAD" },
        { price: "1800", priceCurrency: "CHF" },
        { price: "44800", priceCurrency: "CZK" },
        { price: "14000", priceCurrency: "DKK" },
        { price: "1895", priceCurrency: "EUR" },
        { price: "1600", priceCurrency: "GBP" },
        { price: "16000", priceCurrency: "HKD" },
        { price: "7700", priceCurrency: "ILS" },
        { price: "3000", priceCurrency: "JPY" },
        { price: "28000", priceCurrency: "KRW" },
        { price: "32700", priceCurrency: "MXN" },
        { price: "9500", priceCurrency: "MYR" },
        { price: "3500", priceCurrency: "NZD" },
        { price: "8400", priceCurrency: "PLN" },
        { price: "22200", priceCurrency: "SEK" },
        { price: "2800", priceCurrency: "SGD" },
        { price: "2000", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48550077694254",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-B-2",

      inventoryQuantity: 9999,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-08-18T08:48:33.456Z",
      updatedDate: "2023-08-18T08:48:33.456Z",
      deletedAt: null,
      id: "a872c3f0-fb01-409a-b92d-c8b89b48d87e",
      shopifyId: "gid://shopify/ProductVariant/46503037174062",

      receivedFromShopifyDate: "2023-08-22T11:25:12.000Z",
      shopifyUpdateDate: "2023-08-18T08:48:28.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46503037174062",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Large",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "11200", priceCurrency: "AED" },
        { price: "4800", priceCurrency: "AUD" },
        { price: "4200", priceCurrency: "CAD" },
        { price: "2700", priceCurrency: "CHF" },
        { price: "67200", priceCurrency: "CZK" },
        { price: "20900", priceCurrency: "DKK" },
        { price: "2895", priceCurrency: "EUR" },
        { price: "2400", priceCurrency: "GBP" },
        { price: "23900", priceCurrency: "HKD" },
        { price: "11600", priceCurrency: "ILS" },
        { price: "4500", priceCurrency: "JPY" },
        { price: "41000", priceCurrency: "KRW" },
        { price: "49000", priceCurrency: "MXN" },
        { price: "14200", priceCurrency: "MYR" },
        { price: "5200", priceCurrency: "NZD" },
        { price: "12500", priceCurrency: "PLN" },
        { price: "33200", priceCurrency: "SEK" },
        { price: "4200", priceCurrency: "SGD" },
        { price: "3000", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48550077727022",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-B-3",
      inventoryQuantity: 99999,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
  ],
} as Partial<IPurchasable> as IPurchasable;

export const purchasableRandomProductA = {
  createdDate: "2023-08-16T13:54:21.410Z",
  updatedDate: "2023-08-16T13:54:21.410Z",
  deletedAt: null,
  id: "bd65b42b-06cf-4cae-a03e-a34131f3a80c",
  shopifyId: "gid://shopify/Product/8579679387950",
  __uniqueShopifyId__: "566796a6-41ed-4589-86f4-cc8da3b9331b_gid://shopify/Product/8579679387950",
  receivedFromShopifyDate: "2023-08-22T11:15:17.000Z",
  shopifyUpdateDate: "2023-08-22T11:15:17.000Z",
  shopifyProductStorefrontId: "gid://shopify/Product/8579679387950",
  isActiveInShopify: true,
  isDraftOrArchived: false,
  purchasableName: "Random Product A",
  purchasableImages: ["https://cdn.shopify.com/s/files/1/0732/6643/0254/files/cat1_320x320@2x.jpg?v=1692023330"],
  purchasableSmallImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/files/cat1_320x320@2x.jpg?v=1692023330",
  ],
  description: "",
  smartrrTags: ["SmartrrFilter:Random Product"],
  vnts: [
    {
      createdDate: "2023-08-16T13:54:21.461Z",
      updatedDate: "2023-08-16T13:54:21.461Z",
      deletedAt: null,
      id: "f5520c96-86d7-4e39-8223-5cd793de46fc",
      shopifyId: "gid://shopify/ProductVariant/46450615157038",
      receivedFromShopifyDate: "2023-08-22T11:15:17.000Z",
      shopifyUpdateDate: "2023-08-18T09:07:56.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46450615157038",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Medium",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "7500", priceCurrency: "AED", priceCountry: "AE" },
        { price: "3200", priceCurrency: "AUD", priceCountry: "AU" },
        { price: "2800", priceCurrency: "CAD", priceCountry: "CA" },
        { price: "1800", priceCurrency: "CHF", priceCountry: "CH" },
        { price: "44800", priceCurrency: "CZK", priceCountry: "CZ" },
        { price: "14000", priceCurrency: "DKK", priceCountry: "DK" },
        { price: "1895", priceCurrency: "EUR", priceCountry: "EU" },
        { price: "1600", priceCurrency: "GBP", priceCountry: "GB" },
        { price: "16000", priceCurrency: "HKD", priceCountry: "HK" },
        { price: "7700", priceCurrency: "ILS", priceCountry: "IL" },
        { price: "3000", priceCurrency: "JPY", priceCountry: "JP" },
        { price: "28000", priceCurrency: "KRW", priceCountry: "KR" },
        { price: "32700", priceCurrency: "MXN", priceCountry: "MX" },
        { price: "9500", priceCurrency: "MYR", priceCountry: "MY" },
        { price: "3500", priceCurrency: "NZD", priceCountry: "NZ" },
        { price: "8400", priceCurrency: "PLN", priceCountry: "PL" },
        { price: "22200", priceCurrency: "SEK", priceCountry: "SE" },
        { price: "2800", priceCurrency: "SGD", priceCountry: "SG" },
        { price: "2000", priceCurrency: "USD", priceCountry: "US" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48497508090158",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-A-1",
      inventoryQuantity: 9998,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-08-16T13:54:21.448Z",
      updatedDate: "2023-08-16T13:54:21.448Z",
      deletedAt: null,
      id: "286b1c51-8fe1-45f8-8ba7-bc6abe23f723",
      shopifyId: "gid://shopify/ProductVariant/46450615124270",

      receivedFromShopifyDate: "2023-08-22T11:15:17.000Z",
      shopifyUpdateDate: "2023-08-22T11:10:31.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46450615124270",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Small",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "3800", priceCurrency: "AED", priceCountry: "AE" },
        { price: "1600", priceCurrency: "AUD", priceCountry: "AU" },
        { price: "1400", priceCurrency: "CAD", priceCountry: "CA" },
        { price: "900", priceCurrency: "CHF", priceCountry: "CH" },
        { price: "22400", priceCurrency: "CZK", priceCountry: "CZ" },
        { price: "7000", priceCurrency: "DKK", priceCountry: "DK" },
        { price: "995", priceCurrency: "EUR", priceCountry: "EU" },
        { price: "800", priceCurrency: "GBP", priceCountry: "GB" },
        { price: "8000", priceCurrency: "HKD", priceCountry: "HK" },
        { price: "3900", priceCurrency: "ILS", priceCountry: "IL" },
        { price: "1500", priceCurrency: "JPY", priceCountry: "JP" },
        { price: "14000", priceCurrency: "KRW", priceCountry: "KR" },
        { price: "16400", priceCurrency: "MXN", priceCountry: "MX" },
        { price: "4800", priceCurrency: "MYR", priceCountry: "MY" },
        { price: "1800", priceCurrency: "NZD", priceCountry: "NZ" },
        { price: "4200", priceCurrency: "PLN", priceCountry: "PL" },
        { price: "11100", priceCurrency: "SEK", priceCountry: "SE" },
        { price: "1400", priceCurrency: "SGD", priceCountry: "SG" },
        { price: "1000", priceCurrency: "USD", priceCountry: "US" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48497508057390",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-A-2",
      inventoryQuantity: 9990,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-08-16T13:54:21.477Z",
      updatedDate: "2023-08-16T13:54:21.477Z",
      deletedAt: null,
      id: "9212f3e4-7cb5-409a-af03-f9f8e36122e9",
      shopifyId: "gid://shopify/ProductVariant/46450615189806",
      receivedFromShopifyDate: "2023-08-22T11:15:17.000Z",
      shopifyUpdateDate: "2023-08-14T14:28:40.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46450615189806",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Large",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "11200", priceCurrency: "AED" },
        { price: "4800", priceCurrency: "AUD" },
        { price: "4200", priceCurrency: "CAD" },
        { price: "2700", priceCurrency: "CHF" },
        { price: "67200", priceCurrency: "CZK" },
        { price: "20900", priceCurrency: "DKK" },
        { price: "2895", priceCurrency: "EUR" },
        { price: "2400", priceCurrency: "GBP" },
        { price: "23900", priceCurrency: "HKD" },
        { price: "11600", priceCurrency: "ILS" },
        { price: "4500", priceCurrency: "JPY" },
        { price: "41000", priceCurrency: "KRW" },
        { price: "49000", priceCurrency: "MXN" },
        { price: "14200", priceCurrency: "MYR" },
        { price: "5200", priceCurrency: "NZD" },
        { price: "12500", priceCurrency: "PLN" },
        { price: "33200", priceCurrency: "SEK" },
        { price: "4200", priceCurrency: "SGD" },
        { price: "3000", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48497508122926",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-A-3",
      inventoryQuantity: 99999,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-08-16T13:54:21.477Z",
      updatedDate: "2023-08-16T13:54:21.477Z",
      deletedAt: null,
      id: "9212f3e4-7cb5-409a-af03-f9f8e36122e8",
      shopifyId: "gid://shopify/ProductVariant/46450615189807",
      receivedFromShopifyDate: "2023-08-22T11:15:17.000Z",
      shopifyUpdateDate: "2023-08-14T14:28:40.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46450615189807",
      isActiveInShopify: true,
      isDraftOrArchived: true,
      purchasableVariantName: "Large",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "11200", priceCurrency: "AED" },
        { price: "4800", priceCurrency: "AUD" },
        { price: "4200", priceCurrency: "CAD" },
        { price: "2700", priceCurrency: "CHF" },
        { price: "67200", priceCurrency: "CZK" },
        { price: "20900", priceCurrency: "DKK" },
        { price: "2895", priceCurrency: "EUR" },
        { price: "2400", priceCurrency: "GBP" },
        { price: "23900", priceCurrency: "HKD" },
        { price: "11600", priceCurrency: "ILS" },
        { price: "4500", priceCurrency: "JPY" },
        { price: "41000", priceCurrency: "KRW" },
        { price: "49000", priceCurrency: "MXN" },
        { price: "14200", priceCurrency: "MYR" },
        { price: "5200", priceCurrency: "NZD" },
        { price: "12500", priceCurrency: "PLN" },
        { price: "33200", priceCurrency: "SEK" },
        { price: "4200", priceCurrency: "SGD" },
        { price: "3000", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48497508122927",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-A-4",
      inventoryQuantity: 99999,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-08-16T13:54:21.477Z",
      updatedDate: "2023-08-16T13:54:21.477Z",
      deletedAt: null,
      id: "9212f3e4-7cb5-409a-af03-f9f8e36122e9",
      shopifyId: "gid://shopify/ProductVariant/46450615189808",
      receivedFromShopifyDate: "2023-08-22T11:15:17.000Z",
      shopifyUpdateDate: "2023-08-14T14:28:40.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/46450615189808",
      isActiveInShopify: false,
      isDraftOrArchived: false,
      purchasableVariantName: "Large",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "11200", priceCurrency: "AED" },
        { price: "4800", priceCurrency: "AUD" },
        { price: "4200", priceCurrency: "CAD" },
        { price: "2700", priceCurrency: "CHF" },
        { price: "67200", priceCurrency: "CZK" },
        { price: "20900", priceCurrency: "DKK" },
        { price: "2895", priceCurrency: "EUR" },
        { price: "2400", priceCurrency: "GBP" },
        { price: "23900", priceCurrency: "HKD" },
        { price: "11600", priceCurrency: "ILS" },
        { price: "4500", priceCurrency: "JPY" },
        { price: "41000", priceCurrency: "KRW" },
        { price: "49000", priceCurrency: "MXN" },
        { price: "14200", priceCurrency: "MYR" },
        { price: "5200", priceCurrency: "NZD" },
        { price: "12500", priceCurrency: "PLN" },
        { price: "33200", priceCurrency: "SEK" },
        { price: "4200", priceCurrency: "SGD" },
        { price: "3000", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/48497508122928",
      requiresShipping: true,
      sku: "RANDOM-PRODUCT-A-5",
      inventoryQuantity: 99999,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
  ],
} as Partial<IPurchasable> as IPurchasable;

export const purchasableSnowboardOxygen = {
  createdDate: "2023-03-06T11:56:47.818Z",
  updatedDate: "2023-03-06T11:56:47.818Z",
  deletedAt: null,
  id: "56180d8e-2da4-4b07-b44c-dd3fe5b19433",
  shopifyId: "gid://shopify/Product/8163386196270",
  __uniqueShopifyId__: "566796a6-41ed-4589-86f4-cc8da3b9331b_gid://shopify/Product/8163386196270",
  receivedFromShopifyDate: "2023-06-22T12:08:05.000Z",
  shopifyUpdateDate: "2023-06-22T12:08:05.000Z",
  shopifyProductStorefrontId: "gid://shopify/Product/8163386196270",
  isActiveInShopify: true,
  isDraftOrArchived: false,
  purchasableName: "The Collection Snowboard: Oxygen",
  purchasableImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/products/Main_d624f226-0a89-4fe1-b333-0d1548b43c06_320x320@2x.jpg?v=1678103533",
  ],
  purchasableSmallImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/products/Main_d624f226-0a89-4fe1-b333-0d1548b43c06_320x320@2x.jpg?v=1678103533",
  ],
  description: "",
  smartrrTags: ["SmartrrFilter:Snowboard"],
  vnts: [
    {
      createdDate: "2023-03-06T11:56:47.858Z",
      updatedDate: "2023-05-11T16:06:01.767Z",
      deletedAt: null,
      id: "4c3b5a27-c581-4bca-8c4f-6c4527e529b4",
      shopifyId: "gid://shopify/ProductVariant/44675307503918",
      receivedFromShopifyDate: "2023-06-22T12:08:05.000Z",
      shopifyUpdateDate: "2023-06-22T12:08:05.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/44675307503918",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Default Title",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "382200", priceCurrency: "AED" },
        { price: "161400", priceCurrency: "AUD" },
        { price: "140700", priceCurrency: "CAD" },
        { price: "91400", priceCurrency: "CHF" },
        { price: "2295100", priceCurrency: "CZK" },
        { price: "712500", priceCurrency: "DKK" },
        { price: "95595", priceCurrency: "EUR" },
        { price: "81500", priceCurrency: "GBP" },
        { price: "815500", priceCurrency: "HKD" },
        { price: "393100", priceCurrency: "ILS" },
        { price: "151700", priceCurrency: "JPY" },
        { price: "1391000", priceCurrency: "KRW" },
        { price: "1673500", priceCurrency: "MXN" },
        { price: "483600", priceCurrency: "MYR" },
        { price: "174600", priceCurrency: "NZD" },
        { price: "426900", priceCurrency: "PLN" },
        { price: "1134000", priceCurrency: "SEK" },
        { price: "141000", priceCurrency: "SGD" },
        { price: "102500", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/46720506331438",
      requiresShipping: true,
      sku: "",
      inventoryQuantity: 38,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
  ],
} as Partial<IPurchasable> as IPurchasable;

export const purchasableSnowboardHydrogen = {
  createdDate: "2023-03-06T11:56:48.142Z",
  updatedDate: "2023-03-06T11:56:48.142Z",
  deletedAt: null,
  id: "378e089f-a684-4d70-8f75-416f5e320e15",
  shopifyId: "gid://shopify/Product/8163386294574",
  __uniqueShopifyId__: "566796a6-41ed-4589-86f4-cc8da3b9331b_gid://shopify/Product/8163386294574",
  receivedFromShopifyDate: "2023-08-16T12:56:51.000Z",
  shopifyUpdateDate: "2023-08-16T12:56:51.000Z",
  shopifyProductStorefrontId: "gid://shopify/Product/8163386294574",
  isActiveInShopify: true,
  isDraftOrArchived: false,
  purchasableName: "The Collection Snowboard: Hydrogen",
  purchasableImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/products/Main_320x320@2x.jpg?v=1678103533",
  ],
  purchasableSmallImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/products/Main_320x320@2x.jpg?v=1678103533",
  ],
  description: "",
  smartrrTags: ["SmartrrFilter:Snowboard"],
  vnts: [
    {
      createdDate: "2023-03-06T11:56:48.196Z",
      updatedDate: "2023-05-11T16:06:01.767Z",
      deletedAt: null,
      id: "389cc868-3304-47e9-a9fb-dadedda4ecb4",
      shopifyId: "gid://shopify/ProductVariant/44675307569454",
      receivedFromShopifyDate: "2023-08-16T12:56:51.000Z",
      shopifyUpdateDate: "2023-08-16T12:56:51.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/44675307569454",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Default Title",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "223700", priceCurrency: "AED" },
        { price: "94500", priceCurrency: "AUD" },
        { price: "82400", priceCurrency: "CAD" },
        { price: "53500", priceCurrency: "CHF" },
        { price: "1343500", priceCurrency: "CZK" },
        { price: "417100", priceCurrency: "DKK" },
        { price: "55995", priceCurrency: "EUR" },
        { price: "47800", priceCurrency: "GBP" },
        { price: "477400", priceCurrency: "HKD" },
        { price: "230100", priceCurrency: "ILS" },
        { price: "88800", priceCurrency: "JPY" },
        { price: "814000", priceCurrency: "KRW" },
        { price: "979600", priceCurrency: "MXN" },
        { price: "283100", priceCurrency: "MYR" },
        { price: "102200", priceCurrency: "NZD" },
        { price: "249900", priceCurrency: "PLN" },
        { price: "663800", priceCurrency: "SEK" },
        { price: "82600", priceCurrency: "SGD" },
        { price: "60000", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/46720506396974",
      requiresShipping: true,
      sku: "",
      inventoryQuantity: 35,
      weight: 0,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
  ],
} as Partial<IPurchasable> as IPurchasable;

export const purchasableSnowboardComplete = {
  createdDate: "2023-03-06T11:56:47.971Z",
  updatedDate: "2023-03-06T11:56:47.971Z",
  deletedAt: null,
  id: "850644c4-19f0-4451-ae75-e233a3d9304e",
  shopifyId: "gid://shopify/Product/8163386261806",
  __uniqueShopifyId__: "566796a6-41ed-4589-86f4-cc8da3b9331b_gid://shopify/Product/8163386261806",
  receivedFromShopifyDate: "2023-08-22T10:40:09.000Z",
  shopifyUpdateDate: "2023-08-22T10:40:09.000Z",
  shopifyProductStorefrontId: "gid://shopify/Product/8163386261806",
  isActiveInShopify: true,
  isDraftOrArchived: false,
  purchasableName: "The Complete Snowboard",
  purchasableImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/products/Main_589fc064-24a2-4236-9eaf-13b2bd35d21d_320x320@2x.jpg?v=1678103533",
  ],
  purchasableSmallImages: [
    "https://cdn.shopify.com/s/files/1/0732/6643/0254/products/Main_589fc064-24a2-4236-9eaf-13b2bd35d21d_320x320@2x.jpg?v=1678103533",
  ],
  description: "This PREMIUM snowboard is so SUPERDUPER awesome!",
  smartrrTags: ["SmartrrFilter:Snowboard"],
  vnts: [
    {
      createdDate: "2023-03-06T11:56:48.053Z",
      updatedDate: "2023-05-11T16:06:01.767Z",
      deletedAt: null,
      id: "bd635961-49e2-4924-a80d-14c4adf47293",
      shopifyId: "gid://shopify/ProductVariant/44675307667758",
      receivedFromShopifyDate: "2023-08-22T10:40:09.000Z",
      shopifyUpdateDate: "2023-08-03T15:08:15.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/44675307667758",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Dawn",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "261000", priceCurrency: "AED" },
        { price: "110200", priceCurrency: "AUD" },
        { price: "96100", priceCurrency: "CAD" },
        { price: "62400", priceCurrency: "CHF" },
        { price: "1567300", priceCurrency: "CZK" },
        { price: "486500", priceCurrency: "DKK" },
        { price: "65295", priceCurrency: "EUR" },
        { price: "55700", priceCurrency: "GBP" },
        { price: "556900", priceCurrency: "HKD" },
        { price: "268400", priceCurrency: "ILS" },
        { price: "103600", priceCurrency: "JPY" },
        { price: "950000", priceCurrency: "KRW" },
        { price: "1142800", priceCurrency: "MXN" },
        { price: "330300", priceCurrency: "MYR" },
        { price: "119300", priceCurrency: "NZD" },
        { price: "291500", priceCurrency: "PLN" },
        { price: "774400", priceCurrency: "SEK" },
        { price: "96300", priceCurrency: "SGD" },
        { price: "69995", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/46720506495278",
      requiresShipping: true,
      sku: "",
      unitCost: null,
      inventoryQuantity: 99904,
      weight: 10,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-03-06T11:56:48.098Z",
      updatedDate: "2023-05-11T16:06:01.767Z",
      deletedAt: null,
      id: "d52d7550-50a5-42fb-b1cd-e3083019f3ea",
      shopifyId: "gid://shopify/ProductVariant/44675307733294",

      receivedFromShopifyDate: "2023-08-22T10:40:09.000Z",
      shopifyUpdateDate: "2023-05-19T11:08:46.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/44675307733294",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Electric",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "261000", priceCurrency: "AED" },
        { price: "110200", priceCurrency: "AUD" },
        { price: "96100", priceCurrency: "CAD" },
        { price: "62400", priceCurrency: "CHF" },
        { price: "1567300", priceCurrency: "CZK" },
        { price: "486500", priceCurrency: "DKK" },
        { price: "65295", priceCurrency: "EUR" },
        { price: "55700", priceCurrency: "GBP" },
        { price: "556900", priceCurrency: "HKD" },
        { price: "268400", priceCurrency: "ILS" },
        { price: "103600", priceCurrency: "JPY" },
        { price: "950000", priceCurrency: "KRW" },
        { price: "1142800", priceCurrency: "MXN" },
        { price: "330300", priceCurrency: "MYR" },
        { price: "119300", priceCurrency: "NZD" },
        { price: "291500", priceCurrency: "PLN" },
        { price: "774400", priceCurrency: "SEK" },
        { price: "96300", priceCurrency: "SGD" },
        { price: "69995", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/46720506560814",
      requiresShipping: true,
      sku: "",
      unitCost: null,
      inventoryQuantity: 10997,
      weight: 10,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-03-06T11:56:48.075Z",
      updatedDate: "2023-05-11T16:06:01.767Z",
      deletedAt: null,
      id: "0c9f1f43-c8a7-4d40-9a3c-5241c36689dd",
      shopifyId: "gid://shopify/ProductVariant/44675307700526",
      receivedFromShopifyDate: "2023-08-22T10:40:09.000Z",
      shopifyUpdateDate: "2023-08-22T10:39:16.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/44675307700526",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Powder",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "261000", priceCurrency: "AED" },
        { price: "110200", priceCurrency: "AUD" },
        { price: "96100", priceCurrency: "CAD" },
        { price: "62400", priceCurrency: "CHF" },
        { price: "1567300", priceCurrency: "CZK" },
        { price: "486500", priceCurrency: "DKK" },
        { price: "65295", priceCurrency: "EUR" },
        { price: "55700", priceCurrency: "GBP" },
        { price: "556900", priceCurrency: "HKD" },
        { price: "268400", priceCurrency: "ILS" },
        { price: "103600", priceCurrency: "JPY" },
        { price: "950000", priceCurrency: "KRW" },
        { price: "1142800", priceCurrency: "MXN" },
        { price: "330300", priceCurrency: "MYR" },
        { price: "119300", priceCurrency: "NZD" },
        { price: "291500", priceCurrency: "PLN" },
        { price: "774400", priceCurrency: "SEK" },
        { price: "96300", priceCurrency: "SGD" },
        { price: "69995", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/46720506528046",
      requiresShipping: true,
      sku: "",
      unitCost: null,
      inventoryQuantity: 10995,
      weight: 10,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-03-06T11:56:48.035Z",
      updatedDate: "2023-05-11T16:06:01.767Z",
      deletedAt: null,
      id: "93914576-744e-4425-87b5-a6bbe1954a7a",
      shopifyId: "gid://shopify/ProductVariant/44675307602222",
      receivedFromShopifyDate: "2023-08-22T10:40:09.000Z",
      shopifyUpdateDate: "2023-08-22T10:39:30.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/44675307602222",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Ice",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "261000", priceCurrency: "AED" },
        { price: "110200", priceCurrency: "AUD" },
        { price: "96100", priceCurrency: "CAD" },
        { price: "62400", priceCurrency: "CHF" },
        { price: "1567300", priceCurrency: "CZK" },
        { price: "486500", priceCurrency: "DKK" },
        { price: "65295", priceCurrency: "EUR" },
        { price: "55700", priceCurrency: "GBP" },
        { price: "556900", priceCurrency: "HKD" },
        { price: "268400", priceCurrency: "ILS" },
        { price: "103600", priceCurrency: "JPY" },
        { price: "950000", priceCurrency: "KRW" },
        { price: "1142800", priceCurrency: "MXN" },
        { price: "330300", priceCurrency: "MYR" },
        { price: "119300", priceCurrency: "NZD" },
        { price: "291500", priceCurrency: "PLN" },
        { price: "774400", priceCurrency: "SEK" },
        { price: "96300", priceCurrency: "SGD" },
        { price: "69995", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/46720506462510",
      requiresShipping: true,
      sku: "",
      unitCost: null,
      inventoryQuantity: 9962,
      weight: 10,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
    {
      createdDate: "2023-03-06T11:56:48.125Z",
      updatedDate: "2023-05-11T16:06:01.767Z",
      deletedAt: null,
      id: "34f6975e-eb55-47aa-a2cf-25c791ce741d",
      shopifyId: "gid://shopify/ProductVariant/44675307766062",
      receivedFromShopifyDate: "2023-08-22T10:40:09.000Z",
      shopifyUpdateDate: "2023-08-17T12:25:31.000Z",
      shopifyProductVariantStorefrontId: "gid://shopify/ProductVariant/44675307766062",
      isActiveInShopify: true,
      isDraftOrArchived: false,
      purchasableVariantName: "Sunset",
      purchasableVariantImages: [],
      purchasableVariantSmallImages: [],
      presentmentPrices: [
        { price: "223700", priceCurrency: "AED" },
        { price: "94500", priceCurrency: "AUD" },
        { price: "82400", priceCurrency: "CAD" },
        { price: "53500", priceCurrency: "CHF" },
        { price: "1343400", priceCurrency: "CZK" },
        { price: "417000", priceCurrency: "DKK" },
        { price: "55995", priceCurrency: "EUR" },
        { price: "47700", priceCurrency: "GBP" },
        { price: "477300", priceCurrency: "HKD" },
        { price: "230100", priceCurrency: "ILS" },
        { price: "88800", priceCurrency: "JPY" },
        { price: "814000", priceCurrency: "KRW" },
        { price: "979600", priceCurrency: "MXN" },
        { price: "283100", priceCurrency: "MYR" },
        { price: "102200", priceCurrency: "NZD" },
        { price: "249900", priceCurrency: "PLN" },
        { price: "663800", priceCurrency: "SEK" },
        { price: "82600", priceCurrency: "SGD" },
        { price: "59995", priceCurrency: "USD" },
      ],
      shopifyInventoryId: "gid://shopify/InventoryItem/46720506593582",
      requiresShipping: true,
      sku: "",
      inventoryQuantity: 10998,
      weight: 10,
      weight_unit: "lb",
      inventoryPolicy: "DENY",
    },
  ],
} as Partial<IPurchasable> as IPurchasable;
